import { Buffer } from "buffer";
import React, { useContext, useRef, useState } from "react";
import { styles } from "../Custom";

import * as CryptoJS from "crypto-js";
import { doc, updateDoc } from "firebase/firestore";
import "font-awesome/css/font-awesome.min.css";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { ChatContext } from "../Context/ChatContext";
import { db } from "../firebase";
import ChatHeader from "./ChatHeader";
import Messages from "./Messages";
import { getLocalStorageValue } from "../local-storage-service";

const crypto = require("crypto-browserify");
const Chats = ({ currentUser, messages, tabActive }: any) => {
  const encryptChat = (text: any) => {
    const encryptSecretKey = "@SiMBA.InSuRAnCE";
    const chatEncryptionSecretKey =
      "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";
    const mdString = crypto
      .createHash("md5")
      .update(
        Buffer.from(chatEncryptionSecretKey).toString("ASCII"),
        0,
        chatEncryptionSecretKey.length
      )
      .digest("hex");
    const substr = mdString.substring(0, 16);
    const CHATKEY = CryptoJS.enc.Utf8.parse(substr);
    const CHATIV = CryptoJS.enc.Utf8.parse(mdString.substring(16));
    var encrypted = CryptoJS.AES.encrypt(text, CHATKEY, {
      keySize: 128 / 8,
      iv: CHATIV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  };
  /* Decrypt the Message content */
  const decryptMessage = (stringds: any) => {
    const encryptSecretKey = "@SiMBA.InSuRAnCE";
    const chatEncryptionSecretKey =
      "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";
    let finalKey = chatEncryptionSecretKey;
    let hash = crypto
      .createHash("sha256")
      .update(finalKey, "utf8")
      .digest("hex");

    let key = hash.substring(0, 32);
    let iv = hash.substring(32, 64).substring(0, 16);
    try {
      const encryptedData = Buffer.from(stringds, "base64");
      const tagLength = 16;
      const tag = encryptedData.slice(-tagLength);
      const encrypted = encryptedData.slice(0, -tagLength);
      const decipher = crypto.createDecipheriv("aes-256-gcm", key, iv);
      decipher.setAuthTag(tag);
      const decrypted =
        decipher.update(encrypted, "utf8", "utf8") + decipher.final("utf8");
      return decrypted;
    } catch (error) {
      console.log("error", error);
      return "";
    }
    // const mdString = crypto
    //   .createHash("md5")
    //   .update(
    //     Buffer.from(chatEncryptionSecretKey).toString("ASCII"),
    //     0,
    //     chatEncryptionSecretKey.length
    //   )
    //   .digest("hex");
    // const substr = mdString.substring(0, 16);
    // const CHATKEY = CryptoJS.enc.Utf8.parse(substr);
    // const CHATIV = CryptoJS.enc.Utf8.parse(mdString.substring(16));
    // const decrypted = CryptoJS.AES.decrypt(stringds, CHATKEY, {
    //   keySize: 128 / 8,
    //   iv: CHATIV,
    //   mode: CryptoJS.mode.CBC,
    //   padding: CryptoJS.pad.Pkcs7,
    // });
    // return decrypted.toString(CryptoJS.enc.Utf8);
  };

  const resolved = async (id: any) => {
    let supportInfo = {
      current_support_id: null,
      support_name: null,
      status: "idle",
    };
    const docRef = doc(db, "dev_users", `${id}`);
    const updateStatus = await updateDoc(docRef, supportInfo);
  };

  let { data } = useContext(ChatContext);

  const isEmpty = Object.keys(data.user).length === 0;

  if (isEmpty == true) {
    let sampleData = getLocalStorageValue("selectedUser");
    if (sampleData) {
      data = JSON.parse(sampleData);
    }
  }

  const [text, setText] = useState(""); //for textbox setText and clear after send

  const [chats, setChats] = useState([]);

  const [img, setImg] = useState(""); //send an attachment
  const inputRef = useRef(null);

  const addFile = (event: any) => {
    setImg(event.target.files[0]);
  };

  const [acceptText, setAcceptText] = useState(""); //for textbox setText and clear after send

  return (
    <>
      <Container style={{ paddingRight: 0, width: "100%", maxWidth: "100%" }}>
        <>
          {isEmpty ? (
            <Col xs={12} md={12} style={styles.chatheaderblank}>
              <Col
                xs={12}
                md={12}
                className="chat-title-name"
                style={{
                  display: "block",
                  height: "100vh",
                  margin: "0",
                  padding: "0",
                }}
              >
                <p
                  className="user-header-title"
                  style={{
                    padding: "0",
                    margin: "0",
                    fontWeight: "600",
                    fontSize: "20px",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-150px, -60px)",
                  }}
                >
                  Select User To Start Conversation.
                </p>
              </Col>
            </Col>
          ) : (
            <div>
              <Col
                xs={12}
                md={12}
                style={{
                  backgroundColor: "#cfcfcf",
                  color: "#000000de",
                  fontFamily: "NI",
                  fontWeight: "normal",
                  display: "flex",
                }}
              >
                <Col
                  xs={6}
                  md={6}
                  style={{ display: "flex" }}
                  className="chat-title-name"
                >
                  <span
                    style={{
                      fontFamily: "Arial, Helvetica, sans-serif",
                      width: "3rem",
                      height: "3rem",
                      borderRadius: "50%",
                      background: "#ffb600",
                      fontSize: "1rem",
                      color: "#000",
                      textAlign: "center",
                      lineHeight: "3rem",
                      display: "inline-table",
                      margin: "15px",
                    }}
                  >
                    {decryptMessage(data.user.user_name)
                      ? decryptMessage(data.user.user_name)
                          .split(" ")
                          .splice(0, 2)
                          .map((user_name) => user_name[0])
                          .join("")
                          .toUpperCase()
                      : ""}
                  </span>
                  <p
                    style={{
                      padding: "10px 0 0 0",
                      margin: "0 0 10px 10px",
                      fontWeight: "600",
                      fontSize: "20PX",
                      lineHeight: "56px",
                    }}
                  >
                    {decryptMessage(data.user.user_name)}
                  </p>
                </Col>
                {data ? <ChatHeader tabActive={tabActive}></ChatHeader> : ""}
              </Col>
              <Messages />
            </div>
          )}
        </>
      </Container>
    </>
  );
};

export default Chats;
